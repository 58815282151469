import {useMutation} from "@apollo/client"
import { v4 as uuidv4 } from 'uuid';
import {CreateAssistantSession} from "../../graphql/mutations/CreateAssistantSession"
import CheckoutProgressbar from "@components/checkout/checkoutProgressbar"
import router from "next/router"
import {useCallback, useEffect, useState} from "react"
import {useLocalStorage} from '../../hooks/useLocalStorage'
import {event} from "@utils/analytics"


export const AssistantSelector = ({setShowAssistantSelector}) => {

    useEffect(() => {
        event({
            action: "assistant_load"
        })
    }, [])

    const [selectedOption, setSelectedOption] = useState('')
    const [createSession, {data, loading}] = useMutation(CreateAssistantSession)
    const {get, save} = useLocalStorage()

    const selectOption = (option) => {
        if (selectedOption === option) {
            setSelectedOption('')
        } else {
            setSelectedOption(option)
        }
    }

    const handleGoBackButtonClick = () => {
        setShowAssistantSelector(false)
    }
    const handleContinueButtonClick = useCallback(async () => {
        let uid = get('sanmarino-device-id')
        if (!uid) {
            uid = uuidv4();
            save('sanmarino-device-id', uid)
        }
        const sessionResult = await createSession({
            variables: {
                input: {
                    values: {},
                    finished: false,
                    path: selectedOption,
                    step: 0,
                    reference: uid,
                    substep: 0,
                    wallAreaToCover: 0,
                    floorAreaToCover: 0
                }
            }
        })
        router.push(`/assistant/${selectedOption}/1`)
    }, [selectedOption])

    const options = [
        {
            title: 'Baño',
            key: 'bathroom',
            picUrl: '/assets/images/assistant/steps/choose-space/background-bathroom-space.jpg',
            active: true
        },
        {
            title: 'Cocina',
            key: 'cocina',
            picUrl: '/assets/images/assistant/steps/choose-space/background-kitchen-space.png',
            active: true
        },
        {
            title: 'Living',
            key: 'living',
            picUrl: '/assets/images/assistant/steps/choose-space/background-living-space.png',
            active: true
        },
    ]

    return (
        <div className="assistant container">
            <div className="assistant-padding">
                <CheckoutProgressbar progressValue={0} customNodeCount={['Espacio', 'Estilo', 'Colores', 'Resultado']}/>
            </div>

            <div className="assistant-padding">
                <div className="assistant-header-wrapper">
                    <div className="assistant-header-text">
                        <div className="assistant-header">Paso 1 - ¿Qué quieres reformar?</div>
                        <div className="assistant-subheader">
                            Elige el espacio que quieras diseñar
                        </div>
                    </div>
                </div>
                <div className="assistant-center-content">
                    <div className="content-options-row">
                        {options.map((currentOption,) => {
                            return (
                                <div key={currentOption.key}
                                     className={`z-10 option-wrapper selectable-option-wrapper ${selectedOption === currentOption.key ? 'selected-option' : ''}`}
                                     onClick={() => {
                                         if (currentOption.active) {
                                             selectOption(currentOption.key)
                                         }

                                     }} style={{backgroundImage: `url(${currentOption.picUrl})`}}>
                                    <div className="option-header-wrapper"></div>
                                    <div className="step-description-wrapper">
                                        <div
                                            className="step-description step-description-title">{currentOption.title}</div>
                                    </div>
                                    {selectedOption === currentOption.key && (
                                        <div
                                            className="btn assistant-arrow-btn z-30"
                                            onClick={() => {
                                                handleContinueButtonClick();
                                            }}
                                            style={{
                                                backgroundColor: "#00000080",
                                                width: "100%",
                                                borderRadius: 0,
                                                zIndex: 20,
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)",
                                            }}
                                        >
                                            Continuar
                                            <img
                                                src={"/assets/images/assistant/icons/arrow-right.svg"}
                                            />
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}